<template>
  <div class="DefaultPage">
    <img
      src="/assets/DefaultPage.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {}
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.DefaultPage {
  width: 100%;
  height: 800px;
  position: relative;
  img {
    width: 600px;
    height: 600px;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    margin: auto;
  }
}
</style>
