<template>
  <div>
    <div class="head">
      <el-row>
        <el-col :span="4" class="head_style">
          <router-link to="/">
            <img src="@/assets/logo.png" alt="" style="width: 120px; height: 34px; cursor: pointer" @click="home" />
          </router-link>
        </el-col>
        <el-col :span="17">
          <el-row>
            <el-col :span="3">
              <el-dropdown @command="homePage" size="medium ">
                <div class="router" @click="home"> 首页 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">首页</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="3">
              <el-dropdown @command="MaterialLearning" size="medium ">
                <div class="router"> 资料学习 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">文档资料</el-dropdown-item>
                  <el-dropdown-item command="b">视频资料</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="3">
              <el-dropdown @command="QuestionBankExercise" size="medium ">
                <div class="router" @click="QuestionBankExerciseClick"> 题库练习 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">题库练习</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="3">
              <el-dropdown @command="TrainingPlan" size="medium ">
                <div class="router"> 培训计划 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">线上培训</el-dropdown-item>
                  <el-dropdown-item command="b">线下培训</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="3">
              <el-dropdown @command="OnlineExamination" size="medium ">
                <div class="router"> 在线考试 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">待参加考试</el-dropdown-item>
                  <el-dropdown-item command="b">已结束考试</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="3">
              <el-dropdown @command="ScoreStatistics" size="medium ">
                <div class="router"> 成绩统计 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">个人成绩统计</el-dropdown-item>
                  <!-- <el-dropdown-item command="b" >部门成绩统计</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="3">
              <el-dropdown @command="HiddenDangerManagement" size="medium ">
                <div class="router"> 隐患管理 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">报告处理</el-dropdown-item>
                  <el-dropdown-item command="b">隐患登记</el-dropdown-item>
                  <el-dropdown-item command="c">隐患整改</el-dropdown-item>
                  <el-dropdown-item command="d">隐患验收</el-dropdown-item>
                  <el-dropdown-item command="e">隐患台账</el-dropdown-item>
                  <el-dropdown-item command="f">统计分析</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <!-- <el-col :span="3">
              <el-dropdown @command="RiskControl" size="medium ">
                <div class="router"> 风险管控 </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" >作业风险管控</el-dropdown-item>
                  <el-dropdown-item command="b" >设备风险管控</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row> -->
          </el-row></el-col>
        <el-col :span="3" style="position: relative">
          <!-- <img src="@/assets/touxiang.png" alt="" class="img_style" /> -->
          <!-- <span
            style="margin-left:20px;cursor:pointer;"
            @click="login"
            v-if="!show"
          >请登录</span> -->
          <el-dropdown v-if="show" @command="handleCommand" style="margin-left: 20px">
            <span class="el-dropdown-link" style="font-size:18px;cursor:pointer;"> {{ getCurrentUser.realName }} </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">个人中心</el-dropdown-item>
              <!-- <el-dropdown-item command="a">退出登录</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
    <div style="height: 70px"></div>
    <el-dialog :visible.sync="dialogFormVisible" width="600px" :show-close="false" :center="true" @close="close">
      <div style="width: 100%; text-align: center; margin-bottom: 30px">
        <img src="@/assets/logo.png" alt="" style="width: 140px; height: 38px" />
      </div>
      <el-form :model="form" style="width: 100%">
        <el-form-item style="text-align: center">
          <el-input placeholder="请输入用户名" prefix-icon="el-icon-user-solid" v-model="form.username" style="width: 400px">
          </el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-input placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="form.password" style="width: 400px">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          @click="dialogFormVisible = false ; show = false"
          style="width: 70%; margin-bottom: 30px">登录
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getDDUser
  } from '@/api/mine'
  import { defineComponent } from 'vue'
  export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
    name: 'Header',
    data() {
      return {
        show: true,
        dialogFormVisible: false,
        getCurrentUser: {},
        form: {
          username: '',
          password: ''
        },
        roleIds: this.$store.state.getCurrentUser.roleIds
      }
    },
    created() {
      getDDUser().then((res) => {
        this.getCurrentUser = res.data
      })
    },
    methods: {
      // 首页
      homePage(command) {
        if (command === 'a') {
          this.$router.push('/', () => { }, () => { })
        }
      },
      // 首页（单点）
      home() {
        this.$router.push('/', () => { }, () => { })
      },
      // 资料学习
      MaterialLearning(command) {
        if (command === 'a') { this.$router.push({ path: '/documentLearning', query: { type: 1 } }, () => { }, () => { }) }
        if (command === 'b') { this.$router.push({ path: '/documentLearning', query: { type: 2 } }, () => { }, () => { }) }
      },
      // 题库练习
      QuestionBankExercise(command) {
        if (command === 'a') { this.$router.push({ path: '/questionBankExercise' }, () => { }, () => { }) }
      },
      // 题库练习(单点)
      QuestionBankExerciseClick() {
        this.$router.push({ path: '/questionBankExercise' }, () => { }, () => { })
      },
      // 培训计划
      TrainingPlan(command) {
        if (command === 'a') { this.$router.push({ path: '/OnlineTraining', query: { type: 1 } }, () => { }, () => { }) }
        if (command === 'b') { this.$router.push({ path: '/OnlineTraining', query: { type: 2 } }, () => { }, () => { }) }
      },
      // 在线考试
      OnlineExamination(command) {
        if (command === 'a') { this.$router.push({ path: '/Onlinetest', query: { status2: 0 } }, () => { }, () => { }) }
        if (command === 'b') { this.$router.push({ path: '/Onlinetest', query: { status2: 1 } }, () => { }, () => { }) }
      },
      // 成绩统计
      ScoreStatistics(command) {
        if (command === 'a') { this.$router.push({ path: '/statisticalScores', query: { activeKey: 1 } }, () => { }, () => { }) }
        if (command === 'b') { this.$router.push({ path: '/statisticalScores', query: { activeKey: 2 } }, () => { }, () => { }) }
      },
      // 隐患管理
      HiddenDangerManagement(command) {
        if (command === 'a') {
          if (this.roleIds.includes('615a461453a28c4f736013c26cad5abc')) {
            this.$router.push({ path: '/hiddenManagement', query: { type: 0 } }, () => { }, () => { })
          } else {
            this.$notify({
              title: '警告',
              message: '您不是安全员，请联系管理员！',
              type: 'warning'
            })
          }
        }
        if (command === 'b') {
          if (this.roleIds.includes('615a461453a28c4f736013c26cad5abc')) {
            this.$router.push({ path: '/hiddenManagement', query: { type: 1 } }, () => { }, () => { })
          } else {
            this.$notify({
              title: '警告',
              message: '您不是安全员，请联系管理员！',
              type: 'warning'
            })
          }
        }
        if (command === 'c') { this.$router.push({ path: '/hiddenManagement', query: { type: 2 } }, () => { }, () => { }) }
        if (command === 'd') {
          if (this.roleIds.includes('615a461453a28c4f736013c26cad5abc')) {
            this.$router.push({ path: '/hiddenManagement', query: { type: 3 } }, () => { }, () => { })
          } else {
            this.$notify({
              title: '警告',
              message: '您不是安全员，请联系管理员！',
              type: 'warning'
            })
          }
        }
        if (command === 'e') { this.$router.push({ path: '/hiddenManagement', query: { type: 4 } }, () => { }, () => { }) }
        if (command === 'f') { this.$router.push({ path: '/hiddenManagement', query: { type: 5 } }, () => { }, () => { }) }
      },
      // 风控管理
      RiskControl(command) {
        this.$message('click on item ' + command)
      },
      // 登录
      handleCommand(command) {
        if (command === 'a') { this.$router.push({ path: '/mine' }, () => { }, () => { }) }
        if (command === 'b') {
          return false
        }
      },
      close() {
        this.form.username = ''
        this.form.password = ''
      },

      login() {
        this.dialogFormVisible = true
      },
      handleClose(done) {
        this.dialogFormVisible = false
      }
    }
  })
</script>
<style lang="less">
  .head {
    z-index: 1000;
    height: 70px;
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    line-height: 70px;
    padding: 0 20px;
    border-bottom: 1px solid #d1d1d1
  }

  .head_style {
    text-align: center;
    padding-top: 10px;
  }

  .router {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    color: black;
    font-size: 16px;
    cursor: pointer
  }

  .router:hover {
    color: #fff;
    background: red;
    background: #BD002E;
    border-radius: 40px;
  }

  .img_style {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    top: 10px;
  }
</style>
