import axios from '../utils/request'
/**
 * 登录获取数据
 */
 export function userInfo(params) {
  return axios.get(`/api/auth/dingtalk/userInfo`, {
params: {
    ...params
  }
})
}
/**
 * 查询热门资料(或视频)
 */
 export function AppVisitorTrainControllerFind(params) {
  return axios.get(`/api/app/learn/AppMaterialController/pageOrderByClicks`, {
params: {
    ...params
  }
})
}
/**
 * 查询热门资料(或视频)详情
 */
 export function AppMaterialControllerId(id) {
  return axios.get(`/api/app/learn/AppMaterialController/${id}`)
}
/**
 * 收藏-取消收藏
 */
 export function AppCollectControllerPost(params) {
  return axios.post('/api/app/learn/AppCollectController', { ...params })
}
/**
 * 分页查询收藏-取消收藏数据
 */
 export function AppCollectControllerPage(params) {
  return axios.get(`/api/app/learn/AppCollectController/page`, {
params: {
    ...params
  }
})
}

/**
 * 查询类别(分页)
 */
 export function page(params) {
  return axios.get('/api/app/learn/appCategoryController/page', {
params: {
    ...params
  }
})
}
/**
 * 查询类别(不分页)
 */
 export function find(params) {
  return axios.get('/api/app/learn/appCategoryController/find', {
params: {
    ...params
  }
})
}
/**
 * 查询资料(或视频)  文档学习，视频学习
 */
 export function AppMaterialControllerPost(currentPage, pageSize, params) {
  return axios.post(`/api/app/learn/AppMaterialController/page?currentPage=` + currentPage + `&pageSize=` + pageSize, { ...params })
}
/**
 * 分页查询搜索记录
 */
 export function AppSearchControllerGet(params) {
  return axios.get(`/api/app/learn/AppSearchController/page`, {
params: {
    ...params
  }
})
}
/**
 * 删除搜索记录
 */
 export function deleteAppSearchController(params) {
  return axios.delete('/api/app/learn/AppSearchController', {
data: {
    ...params
  }
})
}

// 题库
/**
 * 根据题库id查询表头
 */
 export function getHeaderId(params) {
  return axios.get(`/api/app/learn/AppQuestionController/getHeader`, {
params: {
    ...params
  }
})
}
/**
 * 根据查询题目详情(返回答案)
 */
 export function AppQuestionControllerGetById(params) {
  return axios.get(`/api/app/learn/AppQuestionController/getById`, {
params: {
    ...params
  }
})
}

/**
 * 新增企业内部练习（或考试）答题记录
 */
 export function AppQuestionRecordControllerPost(params) {
  return axios.post('/api/app/learn/AppQuestionRecordController', { ...params })
}
/**
 * 查询题库目录
 */
 export function AppQuestionControllerFind(params) {
  return axios.get('/api/app/learn/AppQuestionController/find', {
params: {
    ...params
  }
})
}

// 线上培训，线下培训

// 培训考试模块
/**
 * 根据培训计划id查看培训详情
 */
// eslint-disable-next-line camelcase
export function AppTrainingPlanController_findByTrainingPlanId(id) {
  return axios.get(`/api/app/learn/AppTrainingPlanController/${id}`)
}
/**
 * 修改培训用户资料查看进度
 */
export function AppTrainingPlanToUserToMaterialController(params) {
  return axios.put('/api/app/learn/AppTrainingPlanToUserToMaterialController', { ...params })
}

// 培训里面的考试模块接口

/**
 * 线上培训表头
 */
// eslint-disable-next-line camelcase
export function AppTrainingPlanToUserController_getHeader(params) {
  return axios.get(`/api/app/learn/AppTrainingPlanToUserController/getHeader`, {
    params: {
      ...params
    }
  })
}

/**
 * 查看培训考试得分
 */
// eslint-disable-next-line camelcase
export function AppTrainingPlanToUserController_results(params) {
  return axios.post('/api/app/learn/AppTrainingPlanToUserController/results', { ...params })
}
/**
 * 修改用户重考次数（线上培训重新考试）
 */
export function AppTrainingPlanToUserControllerPut(params) {
  return axios.put('/api/app/learn/AppTrainingPlanToUserController/updateRetestNum', { ...params })
}

/**
 * 培训模块上传培训建议
 */
export function opinionPut(params) {
  return axios.put('/api/app/learn/AppTrainingPlanToUserController/opinion', { ...params })
}

/**
 * 查询培训（线上线下）
 */
export function AppTrainingPlanControllerPage(params) {
  return axios.get(`/api/app/learn/AppTrainingPlanController/page`, {
params: {
    ...params
  }
})
}
/**
 * 修改培训（线上线下）状态
 */
 export function updateStatusPut(params) {
  return axios.put('/api/app/learn/AppTrainingPlanToUserController/updateStatus', { ...params })
}

/**
 * 根据培训计划id查询资料
 */
 export function AppMaterialControllerFindByTrainingPlanId(params) {
  return axios.get(`/api/app/learn/AppMaterialController/findByTrainingPlanId`, {
params: {
    ...params
  }
})
}
// 在线考试
/**
 * 在线考试（未结束以及已结束分类）
 */
 export function AppExaminationPlanControllerPage(params) {
  return axios.get(`/api/app/learn/AppExaminationPlanController/page`, {
params: {
    ...params
  }
})
}
// 查询有没有考过
export function AppExaminationPlanControllerId(id) {
  return axios.get(`/api/back/learn/ExaminationPlanController/${id}`)
}
/**
 * 在线考试表头
 */
 export function AppExaminationPlanToUserControllerGetHeader(params) {
  return axios.get(`/api/app/learn/AppExaminationPlanToUserController/getHeader`, {
params: {
    ...params
  }
})
}
/**
 * 在线考试查试题（不返回答案）
 */
 export function AppExaminationPaperToQuestionControllerGetByIdNotAnswer(params) {
  return axios.get(`/api/app/learn/AppExaminationPaperToQuestionController/getByIdNotAnswer`, {
params: {
    ...params
  }
})
}
/**
 * 在线考试查试题（返回答案）
 */
 export function AppExaminationPaperToQuestionControllerGetById(params) {
  return axios.get(`/api/app/learn/AppExaminationPaperToQuestionController/getById`, {
params: {
    ...params
  }
})
}
/**
 * 在线考试查目录
 */
 export function AppExaminationPaperToQuestionControllerFind(params) {
  return axios.get(`/api/app/learn/AppExaminationPaperToQuestionController/find`, {
params: {
    ...params
  }
})
}
/**
 * 查看考试信息
 */
 export function AppExaminationPaperToQuestionControllerResults(params) {
  return axios.post('/api/app/learn/AppExaminationPaperToQuestionController/results', { ...params })
}

// 重新考试
export function retake(params) {
  return axios.put('/api/app/learn/AppExaminationPlanToUserController/retake', { ...params })
}

// 首页功能区域
/**
 *  查询功能显示
 */
 export function AppFunctionConfigControllerFind(params) {
  return axios.get(`/api/app/learn/AppFunctionConfigController/find`, {
params: {
    ...params
  }
})
}

/**
 * 修改功能显示
 */
 export function AppFunctionConfigControllerPut(data) {
  return axios.put('/api/app/learn/AppFunctionConfigController', data)
}

/**
 *  分页查询个人成绩统计
 */
export function AppExaminationPlanToUserControllerPage(params) {
  return axios.get(`/api/app/learn/AppExaminationPlanToUserController/page`, {
    params: {
      ...params
    }
  })
}
