<template>
  <div
    style="
      background: #ffffff;
      border: 1px solid #f0f0f0;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin: 10px;
    ">
    <div
      style="cursor: pointer"
      @click="click(FormData)"
    >
      <img
        :src="FormData.img"
        alt=""
      />
      <div
        class="name"
        :title="FormData.title"
      >{{ FormData.title }}</div>
      <div class="type">{{ FormData.category }}</div>
      <i
        class="el-icon-view"
        style="margin-left: 20px"
      ><span style="display:inline-block;margin-left:10px">{{ FormData.clicks }}</span></i>
      <i
        v-if="FormData.collectNum!==null"
        class="el-icon-star-off"
        style="margin-left: 20px"
      >
        <span style="display:inline-block;margin-left:10px">{{ FormData.collectNum }}</span>
      </i>
      <div style="border: 1px solid #f0f0f0; margin-top: 20px; margin-bottom: 10px" />
    </div>
    <div
      style="height: 50px"
    >
      <i
        class="el-icon-time"
        style="margin:0 10px; float: left; margin-top: 8px"
      > <span>  {{ FormData.createdTime?.substring(0,11) }}</span></i>
      <div v-if="FormData.collectNum!==null">
        <div
          class="collection1"
          v-if="!FormData.collect"
          @click="navCollect(FormData)"
        >
          <span>收藏</span>
        </div>
        <div
          class="collection2"
          v-else
          @click="navCollect(FormData)"
        >
          <span>已收藏</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    // eslint-disable-next-line vue/prop-name-casing, vue/require-default-prop
    FormData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {}
  },
  methods: {
    click(val) {
      this.$router.push({
        path: '/documentLearningDetails',
        query: {
          materialId: val.materialId
        }
      })
    },
    navCollect(FormData) {
      this.$emit('navCollect', FormData)
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: 220px;
}

.name {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* 这里是超出几行省略 */
  overflow: hidden;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.type {
  height: 25px;
  color: #206beb;
  background: rgba(32, 107, 235, 0.1);
  text-align: center;
  line-height: 25px;
  margin-top: 20px;
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
}

.collection1 {
  float: right;
  margin-right: 10px;
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  background: rgba(189, 0, 46, 0.05);
  color: #bd002e;
  border-radius: 40px;
}

.collection2 {
  float: right;
  margin-right: 10px;
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  background: #bd002e;
  color: #fff;
  border-radius: 40px;
}

.collection1:hover,
.collection2:hover {
  background: #BD002E;;
  color: #fff;
}
</style>
