import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/' },
  // 首页
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 文件资料
  {
    path: '/documentLearning',
    name: 'documentLearning',
    component: () => import('@/views/documentLearning/index.vue')
  },
   // 文件资料详情
  {
    path: '/documentLearningDetails',
    name: 'documentLearningDetails',
    component: () => import('@/views/documentLearning/details.vue')
  },
  {
    path: '/questionBankExercise',
    name: '题库练习',
    component: () => import('@/views/questionBankExercise/index.vue')
  },
  {
    path: '/questionBankExercisedetails',
    name: '',
    component: () => import('@/views/questionBankExercise/details.vue')
  },
  {
    path: '/OnlineTraining',
    name: '线上培训',
    component: () => import('@/views/OnlineTraining/index.vue')
  },
  {
    path: '/OnlineTrainingdetails',
    name: '线上培训资料',
    component: () => import('@/views/OnlineTraining/dataDetails.vue')
  },
  {
    path: '/OnlineTrainingdetailsInfo',
    name: '线上培训资料详情',
    component: () => import('@/views/OnlineTraining/dataDetailsInfo.vue')
  },
  {
    path: '/OnlineTrainingdataDetailsTest',
    name: '线上培训考试',
    component: () => import('@/views/OnlineTraining/dataDetailsTest.vue')
  },
  {
    path: '/Onlinetest',
    name: '在线考试',
    component: () => import('@/views/Onlinetest/index.vue')
  },
  {
    path: '/Onlinetestdetails',
    name: '在线考试-试题',
    component: () => import('@/views/Onlinetest/dataDetails.vue')
  },
  {
    path: '/Pass',
    name: '考试结果',
    component: () => import('@/views/Pass/index.vue')
  },
  {
    path: '/examReview',
    name: '考试回顾',
    component: () => import('@/views/examReview/index.vue')
  },
  {
    path: '/statisticalScores',
    name: '成绩统计',
    component: () => import('@/views/statisticalScores/index.vue')
  },
  {
    path: '/mine',
    name: '个人中心',
    component: () => import('@/views/mine/index.vue')
  },
  {
    path: '/dataCollection',
    name: '资料收藏',
    component: () => import('@/views/dataCollection/index.vue')
  },
  {
    path: '/topicCollection',
    name: '题目收藏',
    component: () => import('@/views/topicCollection/index.vue')
  },
  {
    path: '/hiddenManagement',
    name: '隐患管理',
    component: () => import('@/views/hiddenManagement/index.vue'),
    children: [
       // 报告处理
       {
        path: '/hiddenManagement/reportProcessing',
        name: '报告处理',
        component: () => import('@/views/components/reportProcessing/index.vue')
      },
       // 报告处理详情
      {
        path: '/hiddenManagement/reportProcessingInfo',
        name: '隐患详情',
        component: () => import('@/views/components/reportProcessing/reportProcessingInfo.vue')
      },
       // 隐患登记
       {
        path: '/hiddenManagement/hiddenRegistration',
        name: '隐患登记',
        component: () => import('@/views/components/hiddenRegistration/index.vue')
      },
       // 隐患登记（表单）
      {
        path: '/hiddenManagement/hiddenRegistrationInfo',
        name: '隐患登记表单',
        component: () => import('@/views/components/hiddenRegistration/hiddenRegistrationInfo.vue')
      },
      // 隐患整改
      {
        path: '/hiddenManagement/hiddenrectification',
        name: '隐患整改',
        component: () => import('@/views/components/hiddenrectification/index.vue')
      },
        // 隐患整改（表单）
      {
        path: '/hiddenManagement/hiddenrectificationInfo',
        name: '隐患整改表单',
        component: () => import('@/views/components/hiddenrectification/hiddenrectificationInfo.vue')
      },
      // 隐患验收
      {
        path: '/hiddenManagement/hiddenacceptance',
        name: '隐患验收',
        component: () => import('@/views/components/hiddenacceptance/index.vue')
      },
        // 隐患整改（表单）
      {
        path: '/hiddenManagement/hiddenacceptanceInfo',
        name: '隐患验收表单',
        component: () => import('@/views/components/hiddenacceptance/hiddenacceptanceInfo.vue')
      },
      // 隐患台账
      {
        path: '/hiddenManagement/hiddenledger',
        name: '隐患台账',
        component: () => import('@/views/components/hiddenledger/index.vue')
      },
        // 隐患台账（表单）
      {
        path: '/hiddenManagement/hiddenledgerInfo',
        name: '隐患台账表单',
        component: () => import('@/views/components/hiddenledger/hiddenledgerInfo.vue')
      },
      // 统计分析
      {
        path: '/hiddenManagement/statisticalAnalysis',
        name: '统计分析',
        component: () => import('@/views/components/statisticalAnalysis/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
