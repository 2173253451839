import Vue from 'vue'
import Vuex from 'vuex'
import {
  getCurrentUser
} from '@/api/mine'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
     getCurrentUser: null
  },
  getters: {
      getCurrentUserGet (state) {
      return state.getCurrentUser
    }
  },
  mutations: {
    UserMutations(state, row) {
      state.getCurrentUser = row
    }
  },
  actions: {
     UserActions(state) {
      return new Promise((resolve, reject) => {
        getCurrentUser().then(res => {
         state.commit('UserMutations', res.data)
         resolve(res.data)
        })
      })
    }
  },
  modules: {
  }
})
