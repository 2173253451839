import router from '../router/index'
import * as dd from 'dingtalk-jsapi'
import { userInfo } from '@/api/api'
import Cookies from 'js-cookie'
import store from '@/store'
/**
 * 路由前置钩子函数，可以做点什么
 */
router.beforeEach(async function (to, form, next) {
  const token = Cookies.get('TOKEN_ACCOUNT_PC')
  if (token) {
    if (!store.state.getCurrentUser) {
      store.dispatch('UserActions').then((res) => {
        next()
      })
    } else {
      next()
    }
  } else {
    const corpId = process.env.VUE_APP_CORPID
    if (dd.env.platform !== 'notInDingTalk') {
      dd.ready(() => {
        // 获取免登授权码
        dd.runtime.permission
          .requestAuthCode({
            corpId
          })
          .then(
            (onSuccess) => {
              userInfo({ code: onSuccess.code })
                .then((res) => {
                  Cookies.set('TOKEN_ACCOUNT_PC', res.data.tokenValue)
                  store.dispatch('UserActions').then((res) => {
                    next()
                  })
                })
                .catch((e) => {
                  console.log(e)
                })
            },
            (onFail) => {
              alert('err: ' + JSON.stringify(onFail))
            }
          )
      })
    } else {
      // 开发模式
      Cookies.set(
        'TOKEN_ACCOUNT_PC',
        'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6IjIwMjIxMjE1MDEiLCJpc3N1ZWRBdCI6MTY5ODcyMDQwNDQ3OSwiZXhwIjoxNjk4ODA2ODA0LCJzdWIiOiIyMDIyMTIxNTAxIn0.VTwdwxM5CyiuMYSh5SYsWmZd5O8gktYIvsBirvKIsTz464K1YlI4kSfXk1RL4MHFQiAlTEW5S8bHuRBE3ok0Aw'
      )
      next('/')
    }
  }
})
