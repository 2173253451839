<template>
  <div id="app" v-if="$store.state.getCurrentUser">
    <Header></Header>
    <div style="min-height: 600px;">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/layout/header.vue'
import Footer from '@/layout/footer.vue'
export default {
  name: 'Index',
  components: {
    Header,
    Footer
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="less">
body {
  margin: 0 !important;
  min-height:950px;
  min-width:1440px;
}

#app {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  .head-container {
    .filter-item {
      display: inline-block;
      vertical-align: middle;
      input {
        height: 30.5px;
        line-height: 30.5px;
      }
    }
    .el-form-item-label {
      margin: 0 3px 0px 0;
      display: inline-block;
      text-align: right;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      line-height: 30.5px;
      padding: 0 7px 0 7px;
    }
    .el-input__inner{
       height:30px !important;
       .el-range__icon{
        line-height: 21px !important;
       }
       .el-range-separator{
        line-height: 21px !important;
       }
      }
    .el-button+.el-button {
      margin-left: 0 !important;
    }
    .el-select__caret.el-input__icon.el-icon-arrow-up {
      line-height: 30.5px;
    }
    .date-item {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 10px;
      height: 30.5px !important;
      width: 230px !important;
    }
  }
}
</style>
