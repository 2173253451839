<template>
  <div style=" background: #ffffff;">
    <img
      src="@/assets/Rectangle.png"
      alt=""
      style="width: 100%; height: 440px"
    />
    <div class="center">
      <h2 class="H2_style">快捷访问</h2>
      <span class="span_style">常用场景一键直达</span>
      <div class="card">
        <div @click="click(1)">
          <img
            src="@/assets/book.png"
            alt=""
          />
          <span>资料学习</span>
        </div>
        <div @click="click(2)">
          <img
            src="@/assets/Question.png"
            alt=""
          />
          <span>题库练习</span>
        </div>
        <div @click="click(3)">
          <img
            src="@/assets/pen.png"
            alt=""
          />
          <span>在线考试</span>
        </div>
        <div @click="click(4)">
          <img
            src="@/assets/lamp.png"
            alt=""
          />
          <span>隐患管理</span>
        </div>
      </div>
      <div>
        <h2 class="H2_style">热门资料</h2>
        <span class="span_style">高频热门文档</span>
        <div
          class="view"
          @click="click(1)"
        >查看全部></div>
      </div>

      <el-tabs>
        <el-tab-pane label="文档资料">
          <div class="card_documentation">
            <template v-if="documentation1.length>0">
              <div
                v-for="(item, index) in documentation1"
                class="Card-item"
                :key="index"
              >
                <card
                  ref="card"
                  :FormData="item"
                  @navCollect="navCollect"
                ></card>
              </div>
            </template>
            <DefaultPage v-else />
          </div>
        </el-tab-pane>
        <el-tab-pane label="视频资料">
          <div class="card_documentation">
            <template v-if="documentation1.length>0">
              <div
                v-for="(item, index) in documentation2"
                :key="index"
                class="Card-item"
              >
                <card
                  ref="card"
                  :FormData="item"
                  @navCollect="navCollect"
                ></card>
              </div>
            </template>
            <DefaultPage v-else />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import card from '@/components/card.vue'
import DefaultPage from '@/components/DefaultPage.vue'
import { AppVisitorTrainControllerFind, AppCollectControllerPost } from '@/api/api'
export default {
  data() {
    return {
      documentation1: [],
      documentation2: []
    }
  },
  created() {
    this.init()
  },
  components: {
    card,
    DefaultPage
  },
  methods: {
    navCollect(val) {
      const params = {
        //	1-试题 2-资料 3-培训计划
        type: 2,
        // 后期后端从token拿数据
        userId: '1',
        dataId: val.materialId
      }
      AppCollectControllerPost(params)
      AppVisitorTrainControllerFind({ ...params, type: 1 }).then((res) => {
        this.documentation1 = res.data
      })
      AppVisitorTrainControllerFind({ ...params, type: 2 }).then((res) => {
        this.documentation2 = res.data
      })
    },
    init() {
      const params = {
        currentPage: 0,
        pageSize: 10,
        // 文档 1 视频 2
        status: 1
      }
      AppVisitorTrainControllerFind({ ...params, type: 1 }).then((res) => {
        this.documentation1 = res.data
      })
      AppVisitorTrainControllerFind({ ...params, type: 2 }).then((res) => {
        this.documentation2 = res.data
      })
    },
    click(val) {
      if (val === 1) {
        this.$router.push({ path: '/documentLearning', query: { type: 1 } })
      }
      if (val === 2) {
        this.$router.push({ path: '/questionBankExercise' })
      }
      if (val === 3) {
        this.$router.push({ path: '/Onlinetest' })
      }
      // if (val === 4) {

      // }
    }
  }
}
</script>
<style lang="less">
.H2_style {
  display: inline-block;
  margin-right: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.span_style {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: #979797;
}

.center {
  padding: 0 100px;
}

.center_card {
  font-size: 10px;
}

.card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  div {
    width: 20%;
    height: 80px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    position: relative;
    cursor: pointer;

    img {
      position: relative;
      top: 10px;
      width: 40px;
      height: 32px;
    }

    span {
      margin-left: 10px;
      font-size: 18px;
      line-height: 80px;
    }
  }
}

.el-tabs__item {
  font-size: 18px !important;
  color: black !important;
}

.is-active {
  color: red !important;
}

.el-tabs__active-bar {
  background-color: red !important;
}

.card_documentation {
  display: flex;
  flex-wrap: wrap;
  .Card-item {
				width: calc(25% - 10px);
				margin-right: 10px;
				margin-top: 10px;

				&:nth-child(4n) {
					margin-right: 0;
				}
			}
}

.view {
  float: right;
  margin-top: 40px;
  cursor: pointer;
  color: #666666;
}

.view:hover {
  color: blue;
}
</style>
