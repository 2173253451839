var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_ul"},[_c('ul',[_vm._m(0),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(1)}}},[_vm._v("首页")])])]),_c('ul',[_vm._m(1),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(2)}}},[_vm._v("文档资料")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(3)}}},[_vm._v("视频资料")])])]),_c('ul',[_vm._m(2),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(4)}}},[_vm._v("题库练习")])])]),_c('ul',[_vm._m(3),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(5)}}},[_vm._v("线上培训")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(6)}}},[_vm._v("线下培训")])])]),_c('ul',[_vm._m(4),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(7)}}},[_vm._v("待参加考试")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(8)}}},[_vm._v("已结束考试")])])]),_c('ul',[_vm._m(5),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(9)}}},[_vm._v("个人成绩统计")])])]),_c('ul',[_vm._m(6),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(11)}}},[_vm._v("报告处理")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(12)}}},[_vm._v("隐患登记")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(13)}}},[_vm._v("隐患整改")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(14)}}},[_vm._v("隐患验收")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(15)}}},[_vm._v("隐患台账")])]),_c('li',[_c('span',{on:{"click":function($event){return _vm.click(16)}}},[_vm._v("统计分析")])])])]),_c('div',{staticStyle:{"border":"1px solid  rgba(255, 255, 255, 0.2)"}}),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"firstli"},[_c('span',[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"firstli"},[_c('span',[_vm._v("资料学习")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"firstli"},[_c('span',[_vm._v("题库练习")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"firstli"},[_c('span',[_vm._v("培训计划")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"firstli"},[_c('span',[_vm._v("在线考试")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"firstli"},[_c('span',[_vm._v("成绩统计")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"firstli"},[_c('span',[_vm._v("隐患管理")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"footer_bottom"},[_c('div',{staticClass:"footer_bottom_div"},[_vm._v(" 友情链接："),_c('a',{attrs:{"href":""}},[_vm._v("某某系统01")]),_vm._v(" | "),_c('a',{attrs:{"href":""}},[_vm._v("某某系统01")]),_vm._v(" | "),_c('a',{attrs:{"href":""}},[_vm._v("某某系统01")]),_vm._v(" | "),_c('a',{attrs:{"href":""}},[_vm._v("某某系统01")]),_vm._v(" | "),_c('a',{attrs:{"href":""}},[_vm._v("某某系统01")])]),_c('div',{staticStyle:{"margin-top":"20px","padding-bottom":"20px","color":"#fff","font-size":"12px"}},[_vm._v(" Copyright © 2022 青岛软微网络科技有限公司 版权所有 ")])])
}]

export { render, staticRenderFns }