<template>
  <div class="footer">
    <div class="footer_ul">
      <ul>
        <li class="firstli"><span>首页</span></li><!-- 列表 -->
        <li><span @click="click(1)">首页</span></li>
      </ul>
      <ul>
        <li class="firstli"><span>资料学习</span></li><!-- 列表 -->
        <li><span @click="click(2)">文档资料</span></li>
        <li><span @click="click(3)">视频资料</span></li>
      </ul>
      <ul>
        <li class="firstli"><span>题库练习</span></li><!-- 列表 -->
        <li><span @click="click(4)">题库练习</span></li>
      </ul>
      <ul>
        <li class="firstli"><span>培训计划</span></li><!-- 列表 -->
        <li><span @click="click(5)">线上培训</span></li>
        <li><span @click="click(6)">线下培训</span></li>
      </ul>
      <ul>
        <li class="firstli"><span>在线考试</span></li><!-- 列表 -->
        <li><span @click="click(7)">待参加考试</span></li>
        <li><span @click="click(8)">已结束考试</span></li>
      </ul>
      <ul>
        <li class="firstli"><span>成绩统计</span></li><!-- 列表 -->
        <li><span @click="click(9)">个人成绩统计</span></li>
        <!-- <li><span @click="click(10)">部门成绩统计</span></li> -->
      </ul>
      <ul>
        <li class="firstli"><span>隐患管理</span></li><!-- 列表 -->
        <li><span @click="click(11)">报告处理</span></li>
        <li><span @click="click(12)">隐患登记</span></li>
        <li><span @click="click(13)">隐患整改</span></li>
        <li><span @click="click(14)">隐患验收</span></li>
        <li><span @click="click(15)">隐患台账</span></li>
        <li><span @click="click(16)">统计分析</span></li>
      </ul>
      <!-- <ul>
        <li class="firstli"><span>风险管控</span></li>
        <li><span @click="click(17)">作业风险管控</span></li>
        <li><span @click="click(18)">设备风险管控</span></li>
      </ul> -->
    </div>
    <div style="border: 1px solid  rgba(255, 255, 255, 0.2);" />
    <div class="footer_bottom">
      <div class="footer_bottom_div">
        友情链接：<a href="">某某系统01</a> | <a href="">某某系统01</a> | <a href="">某某系统01</a> | <a href="">某某系统01</a> | <a
          href="">某某系统01</a>
      </div>
      <div style="margin-top:20px;padding-bottom:20px;color: #fff;font-size: 12px;">
        Copyright © 2022 青岛软微网络科技有限公司 版权所有
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
    name: 'Footer',
    data() {
      return {
        roleIds: this.$store.state.getCurrentUser.roleIds
      }
    },
    methods: {
      click(val) {
        if (val === 1) {
          this.$router.push('/', () => { }, () => { })
        }
        if (val === 2) {
          this.$router.push({ path: '/documentLearning', query: { type: 1 } }, () => { }, () => { })
        }
        if (val === 3) {
          this.$router.push({ path: '/documentLearning', query: { type: 2 } }, () => { }, () => { })
        }
        if (val === 4) {
          this.$router.push({ path: '/questionBankExercise' }, () => { }, () => { })
        }
        if (val === 5) {
          this.$router.push({ path: '/OnlineTraining', query: { type: 1 } }, () => { }, () => { })
        }
        if (val === 6) {
          this.$router.push({ path: '/OnlineTraining', query: { type: 2 } }, () => { }, () => { })
        }
        if (val === 7) {
          this.$router.push({ path: '/Onlinetest', query: { status2: 0 } }, () => { }, () => { })
        }
        if (val === 8) {
          this.$router.push({ path: '/Onlinetest', query: { status2: 1 } }, () => { }, () => { })
        }
        if (val === 9) {
          this.$router.push({ path: '/statisticalScores', query: { activeKey: 1 } }, () => { }, () => { })
        }
        if (val === 10) {
          this.$router.push({ path: '/statisticalScores', query: { activeKey: 2 } }, () => { }, () => { })
        }
        if (val === 11) {
          if (this.roleIds.includes('615a461453a28c4f736013c26cad5abc')) {
            this.$router.push({ path: '/hiddenManagement', query: { type: 0 } }, () => { }, () => { })
          } else {
            this.$notify({
              title: '警告',
              message: '您不是安全员，请联系管理员！',
              type: 'warning'
            })
          }
        }
        if (val === 12) {
          if (this.roleIds.includes('615a461453a28c4f736013c26cad5abc')) {
            this.$router.push({ path: '/hiddenManagement', query: { type: 1 } }, () => { }, () => { })
          } else {
            this.$notify({
              title: '警告',
              message: '您不是安全员，请联系管理员！',
              type: 'warning'
            })
          }
        }
        if (val === 13) {
          this.$router.push({ path: '/hiddenManagement', query: { type: 2 } }, () => { }, () => { })
        }
        if (val === 14) {
          if (this.roleIds.includes('615a461453a28c4f736013c26cad5abc')) {
            this.$router.push({ path: '/hiddenManagement', query: { type: 3 } }, () => { }, () => { })
          } else {
            this.$notify({
              title: '警告',
              message: '您不是安全员，请联系管理员！',
              type: 'warning'
            })
          }
        }
        if (val === 15) {
          this.$router.push({ path: '/hiddenManagement', query: { type: 4 } }, () => { }, () => { })
        }
        if (val === 16) {
          this.$router.push({ path: '/hiddenManagement', query: { type: 5 } }, () => { }, () => { })
        }
        if (val === 17) {
          this.$message('当前未开发，敬请期待！')
        }
        if (val === 18) {
          this.$message('当前未开发，敬请期待！')
        }
      }
    }
  })
</script>
<style lang="less">
  .footer {
    padding: 40px 130px 0px 80px;
    background: #36373E;

    .footer_ul {
      display: flex;
      justify-content: space-between;

      ul {
        list-style: none;

        li {
          margin-bottom: 30px;

          span {
            cursor: pointer;
            color: rgba(255, 255, 255, 0.6);
            text-decoration: none;
            text-align: center;
            font-size: 12px;
          }
        }

        .firstli {
          span {
            color: #FFFFFF;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }

    .footer_bottom {
      .footer_bottom_div {
        color: #fff;
        margin-top: 20px;

        a {
          color: rgba(255, 255, 255, 0.6);
          text-decoration: none;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
</style>
