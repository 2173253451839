import axios from 'axios'
import qs from 'qs'
import Cookies from 'js-cookie'
import router from '@/router'
import { Notification } from 'element-ui'
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000
})

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // 只针对get方式进行序列化
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: Cookies.get('TOKEN_ACCOUNT_PC')
          ? 'Bearer ' + Cookies.get('TOKEN_ACCOUNT_PC')
          : null
      }
    }
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.errMessage) {
      if (response.data.errMessage === 'token过期') {
        Cookies.remove('TOKEN_ACCOUNT_PC')
        router.push('/')
      } else {
        Notification.error({
          title: '错误',
          message: response.data.errMessage
        })
      }
    }
    return response.data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default instance
