import axios from '../utils/request'
/**
 *  查询考试成就信息
 */
export function examinationStatistics(params) {
  return axios.get(
    `/api/app/learn/AppExaminationPlanToUserController/examinationStatistics`,
    {
      params: {
        ...params
      }
    }
  )
}
/**
 *  查询题库练习积累答题信息
 */
export function appPracticeStatistics(params) {
  return axios.get(
    `/api/app/learn/AppQuestionRecordController/appPracticeStatistics`,
    {
      params: {
        ...params
      }
    }
  )
}
/**
 *  查询线上线下完成率
 */
export function appTrainingPlanStatistics(params) {
  return axios.get(
    `/api/app/learn/AppTrainingPlanController/appTrainingPlanStatistics`,
    {
      params: {
        ...params
      }
    }
  )
}
/**
 *  查询资料库学习信息
 */
 export function logStatistics(params) {
    return axios.get(`/api/app/learn/AppMaterialController/logStatistics`, {
      params: {
        ...params
      }
    })
  }
/**
 * 个人信息接口
 * @param {*}
 */
 export function getCurrentUser() {
    return axios.get('/auth/current')
  }
/**
 * 个人信息接口(钉钉名称)
 * @param {*}
 */
export function getDDUser() {
  return axios.get('/api/auth/userInfo/current')
}
